import { Link } from '@/i18n/routing';
import { Icon } from '@iconify/react';
import Image from 'next/image';

const FooterComponent = () => {
  return (
    <footer className="bg-gradient-to-r from-slate-200 via-emerald-100/70 to-slate-200 shadow-xl pt-12 pb-6 px-10 font-sans tracking-wide relative">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <h2 className="text-slate-900 text-sm uppercase font-semibold mb-4">
              Hızlı Linkler
            </h2>
            <ul className="space-y-3">
              <li>
                <Link
                  href="/game/lobby"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Hemen Oyna
                </Link>
              </li>
              <li>
                <Link
                  href="/blog"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  href="/contact"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  İletişim
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-slate-900 text-sm uppercase font-semibold mb-4">
              Bizi Takip Edin
            </h2>
            <ul className="space-y-3">
              <li>
                <Link
                  href="javascript:void(0)"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Kick
                </Link>
              </li>
              <li>
                <Link
                  href="javascript:void(0)"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Youtube
                </Link>
              </li>
              <li>
                <Link
                  href="javascript:void(0)"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Twitch
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-slate-900 text-sm uppercase font-semibold mb-4">
              Şirket
            </h2>
            <ul className="space-y-3">
              <li>
                <Link
                  href="/about-us"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Hakkımızda
                </Link>
              </li>
              <li>
                <Link
                  href="/privacy-policy"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Gizlilik Politikası
                </Link>
              </li>
              <li>
                <Link
                  href="/terms-of-condition"
                  className="text-gray-700 hover:text-slate-400 text-sm transition-all"
                >
                  Şartlar ve Koşullar
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex items-center lg:justify-center max-sm:-order-1">
            <Link href="/" className="flex justify-center max-md:w-full">
              <Image
                src="/cachemine.png"
                alt="CacheMine"
                width={200}
                height={200}
                priority
                className="active:scale-95 sm:mt-4 hover:shadow-emerald-500 hover:drop-shadow-lg max-md:w-40"
              />
            </Link>
          </div>
        </div>

        <hr className="mt-12 mb-6 border-gray-600" />

        <div className="flex sm:justify-between flex-wrap gap-6">
          <div className="flex space-x-5">
            <Link
              href="javascript:void(0)"
              className="text-gray-700 hover:text-emerald-400 text-sm transition-all"
            >
              <Icon
                icon="fa-brands:twitter-square"
                width={22}
                height={22}
                className="fill-gray-400 hover:fill-green-500"
              />
            </Link>
            <Link
              href="javascript:void(0)"
              className="text-gray-700 hover:text-emerald-400 text-sm transition-all"
            >
              <Icon
                icon="ri:instagram-fill"
                width={22}
                height={22}
                className="fill-gray-400 hover:fill-green-500"
              />
            </Link>
            <Link
              href="javascript:void(0)"
              className="text-gray-700 hover:text-emerald-400 text-sm transition-all"
            >
              <Icon
                icon="mdi:linkedin"
                width={24}
                height={24}
                className="fill-gray-400 hover:fill-green-500 mb-0.5"
              />
            </Link>
            <Link
              href="javascript:void(0)"
              className="text-gray-700 hover:text-emerald-400 text-sm transition-all"
            >
              <Icon
                icon="jam:github"
                width={22}
                height={22}
                className="fill-gray-400 hover:fill-green-500"
              />
            </Link>
          </div>

          <p className="text-gray-700 text-sm">
            © 2024 CacheMine. Tüm hakları saklıdır.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
