'use client';

import Image from 'next/image';
import MenubarComponent from './Menu';
import { Link, usePathname } from '@/i18n/routing';
import ProfileComponent from './Profile';
import { MenuItem } from 'primereact/menuitem';
import { Icon } from '@iconify/react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

const NavbarComponent = () => {
  const [visibleTop, setVisibleTop] = useState(false);

  const pathname = usePathname();

  const items: MenuItem[] = [
    {
      template: () => {
        return (
          <Link
            href="/"
            className={cn('flex items-center hover:text-emerald-600', {
              'text-emerald-600': pathname === '/',
            })}
            onClick={() => setVisibleTop(false)}
          >
            <Icon
              icon="material-symbols:home-and-garden"
              className="mr-1.5"
              width={24}
            />
            <span>Anasayfa</span>
          </Link>
        );
      },
    },
    {
      template: () => {
        return (
          <Link
            href="/game"
            className={cn('flex items-center hover:text-emerald-600', {
              'text-emerald-600': pathname.startsWith('/game'),
            })}
            onClick={() => setVisibleTop(false)}
          >
            <Icon icon="simple-icons:monogame" className="mr-1.5" width={20} />
            <span>Oyun</span>
          </Link>
        );
      },
    },
    {
      template: () => {
        return (
          <Link
            href="/shop"
            className={cn('flex items-center hover:text-emerald-600', {
              'text-emerald-600': pathname.startsWith('/shop'),
            })}
            onClick={() => setVisibleTop(false)}
          >
            <Icon icon="flowbite:store-solid" className="mr-1.5" width={27} />
            <span>Mağaza</span>
          </Link>
        );
      },
    },
    {
      template: () => {
        return (
          <Link
            href="/blog"
            className={cn('flex items-center hover:text-emerald-600', {
              'text-emerald-600': pathname.startsWith('/blog'),
            })}
            onClick={() => setVisibleTop(false)}
          >
            <Icon icon="brandico:blogger" className="mr-1.5" width={20} />
            <span>Blog</span>
          </Link>
        );
      },
    },
    {
      template: () => {
        return (
          <Link
            href="/future"
            className={cn('flex items-center hover:text-emerald-600', {
              'text-emerald-600': pathname.startsWith('/future'),
            })}
            onClick={() => setVisibleTop(false)}
          >
            <Icon icon="game-icons:extra-time" className="mr-1.5" width={24} />
            <span>Gelecek</span>
          </Link>
        );
      },
    },
    {
      template: () => {
        return (
          <Link
            href="/contact"
            className={cn('flex items-center hover:text-emerald-600', {
              'text-emerald-600': pathname.startsWith('/contact'),
            })}
            onClick={() => setVisibleTop(false)}
          >
            <Icon icon="bi:telephone-fill" className="mr-1.5" width={20} />
            <span>İletişim</span>
          </Link>
        );
      },
    },
  ];
  return (
    <nav className="sticky top-0 z-50 h-24 backdrop-blur-md [-webkit-backdrop-filter:blur(12px)] shadow-sm shadow-emerald-500/30 select-none">
      <section className="container flex justify-between items-center h-full">
        <Button
          pt={{
            root: {
              className:
                'bg-transparent border-none backdrop-blur-md [-webkit-backdrop-filter:blur(12px)] lg:hidden focus:shadow-none',
            },
          }}
          onClick={() => setVisibleTop(true)}
        >
          <Icon
            icon="solar:hamburger-menu-broken"
            className="w-16 h-16 max-sm:w-10 max-sm:h-10 border border-green-600 hover:border-green-500 border-solid rounded-full text-emerald-600 hover:text-emerald-500"
          />
        </Button>
        <Sidebar
          header={
            <div className="flex items-center gap-2">
              <Image
                src="/cachemine.png"
                alt="CacheMine"
                width={40}
                height={40}
                priority
                className="active:scale-95 -m-0.5 hover:shadow-emerald-500 hover:drop-shadow-lg"
              />
              <motion.figure
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05, color: 'green' }}
                className="flex items-center gap-2"
              >
                <h2 className="text-xl font-bold">Menü</h2>
              </motion.figure>
            </div>
          }
          visible={visibleTop}
          position="top"
          onHide={() => setVisibleTop(false)}
          pt={{
            root: {
              className: 'h-max bg-opacity-90',
            },
          }}
        >
          <div>
            {items.map((item, index) => {
              const options = {
                onClick: () => console.log(`Clicked ${item.label}`),
                className: 'custom-menuitem-class',
                labelClassName: 'custom-label-class',
                iconClassName: 'custom-icon-class',
                template: item.template,
                element: <div />, // Varsayılan bir React elementi
                props: {}, // Varsayılan boş bir nesne
              };
              return (
                <div key={index} className="p-2">
                  {typeof item.template === 'function'
                    ? item.template(item, options)
                    : null}
                </div>
              );
            })}
          </div>
        </Sidebar>
        <Link href="/">
          <Image
            src="/cachemine.png"
            alt="CacheMine"
            width={140}
            height={140}
            priority
            className="active:scale-95 sm:mt-4 hover:shadow-emerald-500 hover:drop-shadow-lg max-sm:w-20"
          />
        </Link>
        <MenubarComponent items={items} />
        <ProfileComponent />
      </section>
    </nav>
  );
};

export default NavbarComponent;
