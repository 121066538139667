'use client';

import { Link } from '@/i18n/routing';
import { Icon } from '@iconify/react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
// import { toast } from 'react-toastify';
import { useUserStore } from '@/store/features/user';
import { useAuthStore } from '@/store/features/auth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Skeleton } from 'primereact/skeleton';

const ProfileComponent = () => {
  const [open, setOpen] = useState(false);
  const [openOP, setOpenOP] = useState(false);

  const op = useRef<OverlayPanel>(null);
  const { isMobile } = useMediaQuery();

  const { data, fetchData, isLoading: isUserLoading, error } = useUserStore();
  const {
    isAuthenticated,
    checkAuth,
    isLoading: isAuthLoading,
  } = useAuthStore();

  // if (error && isAuthenticated) {
  //   toast.error(error);
  // }

  const pathname = usePathname();

  const menuRight = useRef<Menu>(null);

  useEffect(() => {
    if (!data && !error) {
      fetchData().catch((err) => {
        console.error('Kullanıcı verisi alınırken hata:', err.message);
      });
    }
  }, [data, fetchData, error]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    document.body.style.overflow = open || openOP ? 'hidden' : 'auto';
    document.body.style.paddingRight =
      !isMobile && (open || openOP) ? '10px' : '0';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0';
    };
  }, [open, openOP]);

  const items: MenuItem[] = [
    {
      label: isUserLoading
        ? 'Loading...'
        : data?.nickname?.toUpperCase() || 'Kshup',
      items: [
        {
          template: () => {
            return (
              <Link
                href="/profile/plan"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="tdesign:user-vip-filled"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">Planlarım</span>
              </Link>
            );
          },
        },
        {
          template: () => {
            return (
              <Link
                href="/profile/notification"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="streamline:ringing-bell-notification-solid"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">
                  Bildirimler
                </span>
              </Link>
            );
          },
        },
        {
          label: 'Ayarlar',
          template: () => {
            return (
              <Link
                href="/profile/setting"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="fluent:settings-20-filled"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">Ayarlar</span>
              </Link>
            );
          },
        },
        {
          label: 'Arkadaşlarım',
          template: () => {
            return (
              <Link
                href="/profile/friend"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="gridicons:multiple-users"
                  className="group-hover:text-emerald-500"
                  width={22}
                />
                <span className="group-hover:text-emerald-500">
                  Arkadaşlarım
                </span>
              </Link>
            );
          },
        },
        {
          separator: true,
          className: '!h-3 invisible',
        },
        {
          template: () => {
            return (
              <Link
                href="/auth/logout"
                className="flex items-center w-full h-full gap-2"
              >
                <Icon
                  icon="solar:logout-3-bold"
                  className="group-hover:text-emerald-900"
                  width={22}
                />
                <span className="group-hover:text-emerald-900">Çıkış Yap</span>
              </Link>
            );
          },
        },
      ],
    },
  ];

  const JoinComponent = () => {
    return (
      <>
        <Link
          href={`/auth/login?from=${encodeURIComponent(pathname)}`}
          onClick={() => op.current?.hide()}
        >
          <Button
            icon={
              <Icon
                icon="solar:login-3-bold"
                className="group-hover:text-emerald-500"
                width={22}
              />
            }
            pt={{
              root: {
                className:
                  'gap-2 text-black border-blue-300 hover:border-black hover:border-emerald-500 group p-2 bg-green-500 hover:bg-transparent text-white',
              },
            }}
          >
            <span className="group-hover:text-emerald-500">Giriş Yap</span>
          </Button>
        </Link>
        <Link href="/auth/register" onClick={() => op.current?.hide()}>
          <Button
            icon={
              <Icon
                icon="mdi:register"
                className="group-hover:text-emerald-500"
                width={22}
              />
            }
            pt={{
              root: {
                className:
                  'gap-2 bg-transparent text-black border-black hover:border-emerald-500 group p-2',
              },
            }}
          >
            <span className="group-hover:text-emerald-500">Kayıt Ol</span>
          </Button>
        </Link>
      </>
    );
  };

  return (
    <section className="flex justify-center items-center h-full">
      {isAuthLoading ? (
        <Skeleton
          width="5rem"
          height="55%"
          borderRadius="16px"
          className="mb-2"
        ></Skeleton>
      ) : isAuthenticated ? (
        <Button
          icon={
            <Icon
              icon="game-icons:ram-profile"
              className="group-hover:text-emerald-500"
              width={22}
            />
          }
          onClick={(event) => menuRight.current?.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
          pt={{
            root: {
              className:
                'gap-2 bg-transparent text-black border-black hover:border-emerald-500 group p-2',
            },
          }}
        >
          <span className="group-hover:text-emerald-500">Profil</span>
        </Button>
      ) : (
        <>
          <div className="hidden max-sm:flex">
            <Button
              type="button"
              label="Katıl"
              onClick={(e) => op.current?.toggle(e)}
            />
            <OverlayPanel
              ref={op}
              onShow={() => setOpenOP(true)}
              onHide={() => setOpenOP(false)}
              pt={{
                root: {
                  className: 'bg-transparent min-w-[200px] max-w-[300px]',
                },
                content: {
                  className:
                    'flex flex-col gap-2 bg-transparent backdrop-blur-sm [-webkit-backdrop-filter:blur(4px)] [&>a>button]:w-full',
                },
              }}
            >
              <JoinComponent />
            </OverlayPanel>
          </div>
          <div className="flex gap-2 max-sm:hidden">
            <JoinComponent />
          </div>
        </>
      )}
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
        pt={{
          root: {
            className: 'p-0 rounded-md mt-[26px]',
          },
          submenuHeader: {
            className:
              'bg-primary/90 text-white first:rounded-t-md last:bg-red-500/90',
          },
          menuitem: {
            className:
              'flex items-center px-4 h-10 not:last:hover:bg-slate-200 cursor-pointer group last:bg-red-500/90 last:hover:bg-red-400 last:text-white',
          },
        }}
        onShow={() => setOpen(true)}
        onHide={() => setOpen(false)}
      />
    </section>
  );
};

export default ProfileComponent;
