'use client';

import primeLocales from '@/public/primeLocale.json';
import { useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import { addLocale } from 'primereact/api';
import LoadingComponent from '@/components/Loading';
import NavbarComponent from '@/components/Layout/Navbar';
import HeaderComponent from '@/components/Layout/Header';
import ScrollToTopButton from '@/components/ScrollToTopButton';
import FooterComponent from '@/components/Layout/Footer';

type Props = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: Props) => {
  addLocale('tr', primeLocales.tr);

  const locale = useLocale();

  useEffect(() => {
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);

  const [load, setLoad] = useState(true);
  const [loadTime, setLoadTime] = useState(0);

  useEffect(() => {
    const startTime = performance.now();

    const handleLoad = () => {
      const endTime = performance.now();
      setLoadTime(endTime - startTime);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setLoad(false);
      },
      loadTime > 1000 ? 0 : 1000 - loadTime
    );

    return () => clearTimeout(timeout);
  }, [loadTime]);

  return load ? (
    <LoadingComponent />
  ) : (
    <>
      <div className="bg-sky-50 dark:bg-primary/90">
        <HeaderComponent />
        <NavbarComponent />
        <div className="min-h-[calc(100vh-10rem)]">{children}</div>
        <FooterComponent />
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default MainLayout;
